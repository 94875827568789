import { useRef, useEffect } from 'react';
import './ReviewCard.css'
import { useVideoContext } from '../VideoContext/VideoContext'

const ReviewCard = ({video_path}) => {

    const videoRef = useRef(null);
    const { activeVideo, setActiveVideo } = useVideoContext()

    const isVideo = video_path.endsWith('.mp4');

    const handleVideoClick = () => {
        const video = videoRef.current;
        
        if (video.paused) {
            if (activeVideo && activeVideo !== video && activeVideo.pause) {
                activeVideo.pause();
            }

            video.play();
            setActiveVideo(video);
        } else {
            video.pause();
        }
    };

    useEffect(() => {
        if (videoRef.current && activeVideo && activeVideo !== videoRef.current) {
            videoRef.current.pause();
        }
    }, [activeVideo]);

    return (
        <div className='review-card'>
            {isVideo ? (
                <video
                    src={video_path}
                    ref={videoRef}
                    height="550.99px"
                    draggable={false}
                    playsInline
                    preload="metadata"
                    onClick={handleVideoClick}
                />
            ) : (
                <img
                    src={video_path}
                    alt="Review Thumbnail"
                    // height="400.99px"
                    draggable={false}
                />
            )}
        </div>
    );

};

export default ReviewCard;