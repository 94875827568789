import ProjectCard from "../ProjectCard/ProjectCard";
import ProjectCarousel from "../ProjectCarousel/ProjectCarousel";
import "./Projects.css"

const Projects = () => {

    const projects = [
        {
            img_path: require("../../img/webapp/1.jpg"),
            video_path: require("../../img/webapp/1.mp4"),
            title: "«Тапалка» для Crypto проекта",
            desc: "Пользователи зарабатывают токены, тапая и выполняя задания",
        },
        {
            img_path: require("../../img/webapp/2.jpg"),
            video_path: require("../../img/webapp/2.mp4"),
            title: "Нестандартная «тапалка» для мемкоина.",
            desc: "Добавили прокачку персонажей для удержания и вовлечения юзеров",
        },
        {
            img_path: require("../../img/webapp/3.jpg"),
            video_path: require("../../img/webapp/3.mp4"),
            title: "Интернет-магазин с игрой для заработка баллов",
            desc: "Пользователи могут приобрести товар, воспользоваться реферальной программой или заработать баллы, играя",
        }
    ];

    return (
        <div className="projects-container" id="projects">
            <div className="projects-top">
                <p>Примеры WebApp</p>
                <span>Чтобы узнать стоимость вашего проекта или помочь с ТЗ - обращайтесь по контактам ниже</span>
            </div>
            <ProjectCarousel projects={projects}></ProjectCarousel>
        </div>
    );
};

export default Projects;