import './App.css';
import About from './components/About/About';
import Home from './components/Home/Home';
import Projects from './components/Projects/Projects';
import Work from './components/Work/Work';
import { VideoProvider } from './components/VideoContext/VideoContext';

function App() {
  return (
    <div className='App'>
      <Home />
      <Projects />
      <About />
      <VideoProvider>
        <Work />
      </VideoProvider>
    </div>
  );
}

export default App;
