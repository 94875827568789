
import Slider from 'react-slick';
import ReviewCard from '../ReviewCard/ReviewCard';
import './Work.css'

const Work = () => {

    const reviews = [
        {
            video_path: require("../../img/reviews/1.mp4")
        },
        {
            video_path: require("../../img/reviews/2.mp4")
        },
        {
            video_path: require("../../img/reviews/3.mp4")
        },
        {
            video_path: require("../../img/reviews/4.mp4")
        },
        {
            video_path: require("../../img/reviews/5.jpg")
        },
        {
            video_path: require("../../img/reviews/6.jpg")
        },
    ];

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="work-container" id="work">
            <div className='work-title'>
                <p>Работаем поэтапно</p>
            </div>
            <div className='work-info'>
                <div className='stage-cards'>
                    <div className="stage-card">
                        <div className='left-block'>
                            <div className='circle'>01</div>
                        </div>
                        <div className='right-block'>
                            <p>Получаем от Вас ТЗ или составляем его вместе</p>
                        </div>
                    </div>
                    <div className="stage-card">
                        <div className='left-block'>
                            <div className='circle'>02</div>
                        </div>
                        <div className='right-block'>
                            <p>Создаем дизайн и прототип всего приложения</p>
                        </div>
                    </div>
                    <div className="stage-card">
                        <div className='left-block'>
                            <div className='circle'>03</div>
                        </div>
                        <div className='right-block'>
                            <p>Верстаем WebApp и подключаем весь функционал</p>
                        </div>
                    </div>
                    <div className="stage-card">
                        <div className='left-block'>
                            <div className='circle'>04</div>
                        </div>
                        <div className='right-block'>
                            <p>Даю рекомендации по маркетингу проекта и делюсь опытом прошлых запусков</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='reviews'>
                <div className='reviews-title'>
                    <p>Отзывы по работе со мной</p>
                </div>
                <div className='reviews-block'>
                    <Slider {...settings}>
                        {reviews.map((image, index) => (
                            <ReviewCard key={index} video_path={image.video_path} />
                        ))}
                    </Slider>
                </div>
            </div>
            <div className='contacts'>
                <div className='contact-card'>
                    <div className='image'>
                        <img src={require("../../img/case.png")} alt="case" width="56.47px" height="56.47px" />
                    </div>
                    <div className='contact-info'>
                        <p>Телеграм канал с кейсами</p>
                        <span>@deniscases</span>
                    </div>
                </div>
                <div className='contact-card'>
                    <div className='image'>
                        <img src={require("../../img/message.png")} alt="message" width="56.47px" height="56.47px" />
                    </div>
                    <div className='contact-info'>
                        <p>Консультация по вашему проекту</p>
                        <span>@denworks</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Work;